import React from 'react'
import 'animate.css'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Landing from './views/Landing'

function App() {
  return (
    <BrowserRouter>
      <Routes >
        <Route path='/' element={<Landing/>}/>
        <Route path='/:user' element={<Landing/>}/>
      </Routes>   
    </BrowserRouter>   
  );
}

export default App
