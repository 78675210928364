import React, { useEffect, useState } from 'react'
import WOW from 'wow.js'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { FiChevronDown } from 'react-icons/fi'
import { CgClose } from 'react-icons/cg'
import { BsFacebook, BsInstagram, BsWhatsapp, BsFillTelephoneFill } from 'react-icons/bs'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import bannerA from '../assets/img/bannerA.jpg'
import bannerB from '../assets/img/bannerB.jpg'
import bannerC from '../assets/img/bannerC.jpg'
import city from '../assets/img/city.jpg'
import map from '../assets/img/map.jpg'
import g1 from '../assets/img/galery/1.png'
import g2 from '../assets/img/galery/2.png'
import g3 from '../assets/img/galery/3.png'
import g4 from '../assets/img/galery/4.png'
import g5 from '../assets/img/galery/5.png'
import t1 from '../assets/img/t1.png'
import t2 from '../assets/img/t2.png'
import t3 from '../assets/img/t3.png'
import iconA from '../assets/img/iconA.png'
import iconB from '../assets/img/iconB.png'
import iconC from '../assets/img/iconC.png'
import iA from '../assets/img/iA.png'
import iB from '../assets/img/iB.png'
import iC from '../assets/img/iC.png'
import iD from '../assets/img/iD.png'
import iE from '../assets/img/iE.png'
import iF from '../assets/img/iF.png'
import iG from '../assets/img/iG.png'
import iH from '../assets/img/iH.png'
import iI from '../assets/img/iI.png'
import iJ from '../assets/img/iJ.png'
import iK from '../assets/img/iK.png'
import earth from '../assets/img/earth.jpg'
import toast, { Toaster } from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { Helmet } from "react-helmet"

function Landing() {    
    const { user } = useParams()
    const [number, setNumber] = useState()
    const [pixel, setPixel] = useState()
    const [screen, setScreen] = useState(g1)

    const date = new Date()
    new WOW().init()
    AOS.init()

    useEffect(() => {
      if(user === 'u1') {
        setNumber(+573222688851)
        setPixel(
          <>
            <Helmet>
              <script id="facebook-pixel-script">
                {`
                  !function(f,b,e,v,n,t,s)
                  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)}(window,document,'script',
                  'https://connect.facebook.net/en_US/fbevents.js');
                  fbq('init', '1756969851389360'); 
                  fbq('track', 'PageView');
                `}
              </script>             
            </Helmet>
            <img height="1" width="1" alt="" src="https://www.facebook.com/tr?id=1756969851389360&ev=PageView&noscript=1"/>      
          </>                

        )
      } else {
        setNumber(+573023054256)
        setPixel(
          <>
            <Helmet>
            <script id="facebook-pixel-script">
              {`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window,document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '1400206477214188'); 
                fbq('track', 'PageView');
              `}
            </script>            
          </Helmet>
          <img height="1" width="1" alt="" src="https://www.facebook.com/tr?id=1400206477214188&ev=PageView&noscript=1"/>          
          </>
        )
      }
    },[user]);

    function getFullscreen(img){
      setScreen(img)
      document.getElementById('modal').style.display = "block"     
    }

    function close(){
      document.getElementById('modal').style.display = "none";     
    }

    async function wtp () {  
      //user
      let city = document.form.city.value
      let name = document.form.name.value
      let phone = document.form.phone.value
  
      if(city!=='' && name!=='') {
        let text = "Hola Nombradia, me gustaría pedir una cotización  %0A"  
        
        text += `%0A*Cliente:* ${name}%0A`
        text += `*Ciudad:* ${city}%0A`
        text += `*Contacto:* ${phone}%0A`
        text += `%0A_Registro desde landing_%0A`
        window.open(`https://wa.me/${number}?text=${text}`, '_blank');
      } else {
        toast.error('Por favor complete sus datos')
        document.form.city.focus()
      }
    }

    async function phone () {  
      let phone = document.getElementById('number-phone').value;
      document.form.phone.value = phone
      document.form.city.focus()
    }
  
    return (
      <div className="landing">
        
        {pixel}

        <div className="modal animate__animated animate__fadeInRight animate__faster" id="modal" >
          <div className="text-end">
            <button type="button" onClick={() => close()} className="border-0 bg-black h1 text-light"><CgClose/></button>
          </div>
          <br/>
          <div className="text-center">
            <img src={screen} alt="" className="img-modal"/>
          </div>
        </div>
        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={10}
          containerClassName=""
          containerStyle={{
          }}
          toastOptions={{
            // Define default options
            className: '',
            duration: 5000,
            style: {
              background: '#182C3F',
              color: '#fff',
              font: 'menu',
              fontSize: '13.5px',
              borderRadius: '0'
            },
        
            // Default options for specific types
            success: {
              duration: 3000,
              theme: {
                primary: 'green',
                secondary: 'black',
              },
            },
          }}
          />  

        <a href={`https://wa.me/c/${number}`} rel="noreferrer" target="_blank" className="sticky-button zoom animation">        
        </a>
        <div className="texture-right"></div>
        <div className="texture-left"></div>

        <header>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-6">
                <div className="logoA" data-aos="fade-right" data-aos-duration="2000"></div>
              </div>
              <div className="col-6">
                <div className="logoB" data-aos="fade-left" data-aos-duration="2000"></div>
              </div>
              <div className="col-12 text-light text-center mt-5" data-aos="fade-down" data-aos-duration="2000">
                <h1 className="h0">Lotes campestres</h1>
                <h5 className="f-berlin">Casas . Lotes campestres . Zonas de lujo</h5>
              </div>
              <div className="col-12 col-lg-6 text-center mt-3"  data-aos="fade-down" data-aos-duration="2000">
                
                <input className="fw-light" type="number" placeholder="Registre su número y reciba un 20% de descuento" id="number-phone"/>
                <a href="#seventh" className="next-dark zoom" onClick={() => phone()}>
                </a>
              </div>
              <a href="#first" className="down small text-decoration-none text-light f-berlin text-center" >
                <span className="d-block m-0 p-0">VER MÁS</span>
                <FiChevronDown className="h1 m-0 p-0 zoom"/>
              </a>
            </div>
          </div>
        </header>

        <section id="first" >
          <div className="container my-5 pt-5">
            <div className="row">
              <div className="col">
              <Swiper
                  modules={[Autoplay, Navigation, Pagination]}
                  spaceBetween={50}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  autoplay={{
                    delay: 5000,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: false
                  }}
                  loop
                >
                  <SwiperSlide>
                    <h1 className="f-berlin text-center text-blue mb-5">
                      Sea uno de los primeros que pueda decir:
                      <br/>
                      !ESTA TIERRA ES MÍA!
                    </h1>
                    <img className="slide" src={bannerA} alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                    <h1 className="f-berlin text-center text-blue mb-5">
                      Condominio campestre con Lujo de amenidades,
                      zonas verdes y recreativas  para que vivas momentos
                      inolvidables en familia sin salir de casa 
                    </h1>
                    <img className="slide" src={bannerB} alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                    <h1 className="f-berlin text-center text-blue mb-5">
                      Servicios públicos garantizados. <br/>
                      Únicos en la zona con acueducto y alcantarillado propio,
                      sistema de cableado eléctrico subterráneo y cobertura móvil
                    </h1>
                    <img className="slide" src={bannerC} alt=""/>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </section>

        <section id="second" data-aos="flip-left">
          <div className="container my-5">
            <div className="row">
              <div className="col-12">
                <h1 className="f-berlin text-center text-blue mb-5">
                  !Cartagena, un paraíso tropical, destino Clave de inversión!
                </h1>
                <img className="w-100 mb-3 rounded" src={city} alt=""/>
                <p className="text-center">A solo 13 km de Cartagena, via Turbaco, somos la mejor oportunidad de inversión, garantizada plusvalía en 6 meses o menos</p>
              </div>
              <div className="col text-center my-lg-5">
                  <span className="text-blue h2 f-berlin mb-0 d-block">Reclama bono</span>
                  <a href="#seventh" className="d-inline-block mt-3 border-0 p-2 px-5 rounded-pill bg-green f-berlin h2 text-white pe-5 text-decoration-none animation zoom">
                    15 millones 
                    <small> !Ahora! &nbsp; &nbsp;</small>
                    <div className="next"></div>
                  </a>
                  <small className="d-block">Cupos limitados</small>
              </div>
            </div>
          </div>
        </section>

        <section id="third" data-aos="fade-up">
          <h1 className="f-berlin text-center text-blue mb-3">
            ¿Porque somos el mejor proyecto para invertir?
          </h1>
          <h5 className="mb-3 text-center text-secondary">Por ubicación, valorización, paisajes, turismo y crecimiento.</h5>
          <Swiper
            modules={[Autoplay, Navigation]}
            spaceBetween={0}
            slidesPerView={2.5}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 5000,
              pauseOnMouseEnter: true,
              disableOnInteraction: false
            }}
            loop
            className="d-none d-lg-block"
          >
            <SwiperSlide>
              <img className="slide" src={g1} alt="" id="g1" onClick={() => getFullscreen(g1)} />
            </SwiperSlide>
            <SwiperSlide>
              <img className="slide" src={g2} alt="" id="g2" onClick={() => getFullscreen(g2)}/>
            </SwiperSlide>
            <SwiperSlide>
              <img className="slide" src={g3} alt="" id="g3" onClick={() => getFullscreen(g3)}/>
            </SwiperSlide>
            <SwiperSlide>
              <img className="slide" src={g4} alt="" id="g4" onClick={() => getFullscreen(g4)}/>
            </SwiperSlide>
            <SwiperSlide>
              <img className="slide" src={g5} alt="" id="g5" onClick={() => getFullscreen(g5)}/>
            </SwiperSlide>
          </Swiper>
          <Swiper
            modules={[Autoplay, Navigation]}
            spaceBetween={0}
            slidesPerView={1.5}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 5000,
              pauseOnMouseEnter: true,
              disableOnInteraction: false
            }}
            loop
            className="d-block d-lg-none"
          >
            <SwiperSlide>
              <img className="slide" src={g1} alt="" id="g1" onClick={() => getFullscreen(g1)} />
            </SwiperSlide>
            <SwiperSlide>
              <img className="slide" src={g2} alt="" id="g2" onClick={() => getFullscreen(g2)}/>
            </SwiperSlide>
            <SwiperSlide>
              <img className="slide" src={g3} alt="" id="g3" onClick={() => getFullscreen(g3)}/>
            </SwiperSlide>
            <SwiperSlide>
              <img className="slide" src={g4} alt="" id="g4" onClick={() => getFullscreen(g4)}/>
            </SwiperSlide>
            <SwiperSlide>
              <img className="slide" src={g5} alt="" id="g5" onClick={() => getFullscreen(g5)}/>
            </SwiperSlide>
          </Swiper>
          <div className="container mb-5 mt-lg-3">
            <div className="row">
              <div className="col-12 col-lg-4 text-center px-5">
                <img src={iconA} alt="" className="icon m-2"/>
                <h2 className="text-blue f-berlin mb-3">Acueducto</h2>
                <p>Acueducto propio para garantizar agua potable todos los días del año.</p>
              </div>
              <div className="col-12 col-lg-4 text-center px-5">
                <img src={iconB} alt="" className="icon m-2"/>
                <h2 className="text-blue f-berlin mb-3">Alcantarillado</h2>
                <p>Alcantarillado moderno para hacer uso limpio y adecuado de aguas residuales.</p>
              </div>
              <div className="col-12 col-lg-4 text-center px-5">
                <img src={iconC} alt="" className="icon m-2"/>
                <h2 className="text-blue f-berlin mb-3">Alumbrado</h2>
                <p>Eco alumbrado, sistema de energía limpia con cableado subterráneo.</p>
              </div>
            </div>
            <div className="row mt-lg-5 text-center">
              <h2 className="d-block d-lg-none text-blue f-berlin mb-3 py-1 text-center">Todas las amenidades</h2>
              <div className="col-6 col-lg-3">
                <p>
                  <img src={iA} alt="" className="icon-sm m-2"/>
                  <br/>Kiosco de Eventos
                </p>
                <p>
                  <img src={iB} alt="" className="icon-sm m-2"/>
                  <br/>Parque para niños
                </p>
                <p>
                  <img src={iC} alt="" className="icon-sm m-2"/>
                  <br/>Piscina para adultos/niños
                </p>
              </div>
              <div className="col-6 col-lg-3">
                <p>
                  <img src={iD} alt="" className="icon-sm m-2"/>
                  <br/>Cancha multifuncional
                </p>
                <p>
                  <img src={iE} alt="" className="icon-sm m-2"/>
                  <br/>6.000 m2 zonas verdes
                </p>
                <p>
                  <img src={iF} alt="" className="icon-sm m-2"/>
                  <br/>Circuito cerrado de TV
                </p>
              </div>
              <div className="col-6 col-lg-3">
                <p>
                  <img src={iG} alt="" className="icon-sm m-2"/>
                  <br/>2 Jacuzzis
                </p>
                <p>
                  <img src={iH} alt="" className="icon-sm m-2"/>
                  <br/>Oratorio
                </p>
                <p>
                  <img src={iI} alt="" className="icon-sm m-2"/>
                  <br/>Zona BBQ
                </p>
              </div>
              <div className="col-6 col-lg-3">
                <p>
                  <img src={iJ} alt="" className="icon-sm m-2"/>
                  <br/>Zona de mascotas
                </p>
                <p>
                  <img src={iK} alt="" className="icon-sm m-2"/>
                  <br/>Doble Portería
                </p>
              </div>
            </div>
          </div>
        </section>
          
        <section id="fourth" data-aos="fade-up">
          <div className="container my-5">
            <div className="row px-3 px-lg-0">
              <div className="col-12">
                <h1 className="f-berlin text-center text-blue mb-5">
                  Ya son muchas familias beneficiadas con nuestros descuentos por PREVENTA
                </h1>
              </div>
              <div className="col-12 col-lg-4 text-right text-lg-center p-2 p-lg-3 pt-0">
                <div className="row">
                  <div className="col-3 col-lg-12 text-center">
                    <img className="mb-3 img-t" src={t1} alt=""/>
                  </div>
                  <div className="col-9 col-lg-12">
                    <h2 className="f-berlin text-blue">
                      Flía Díaz Bolívar
                    </h2>
                    <p>Adquirió un terreno de 288 m2 y esta lista para comenzar a construir.</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 text-right text-lg-center p-2 p-lg-3">
                <div className="row">
                  <div className="col-3 col-lg-12 text-center">
                    <img className="mb-3 img-t" src={t2} alt=""/>
                  </div>
                  <div className="col-9 col-lg-12">
                    <h2 className="f-berlin text-blue">
                    Antonio Savaleta
                    </h2>
                    <p>Compró ésta semana y su inversión se ha valorizado en un 20%.</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 text-right text-lg-center p-2 p-lg-3">
                <div className="row">
                  <div className="col-3 col-lg-12 text-center">
                    <img className="mb-3 img-t" src={t3} alt=""/>
                  </div>
                  <div className="col-9 col-lg-12">
                    <h2 className="f-berlin text-blue">
                    María Esperanza
                    </h2>
                    <p>Encontró los mejores precios y perfectos a su alcance.</p>
                  </div>
                </div>
              </div>
              <div className="col text-center my-lg-5 my-3">
                  <h2 className="text-blue f-berlin mb-0 d-block"> Precios de Preventa, ¡Cotiza ahora! </h2>
                  <a href="#seventh" className="d-inline-block text-decoration-none mt-3 border-0 p-2 px-5 rounded-pill bg-green f-berlin h2 text-white pe-5 animation zoom">
                    Obtener un 20% de descuento
                    <div className="next"></div>
                  </a>
                  <br/>
                  <a href="#seventh" rel="noreferrer" target="_blank" className="text-decoration-none text-dark">
                    <small>*Aplican términos y condiciones</small>
                  </a>
              </div>
            </div>
          </div>
        </section>

        <section id="fifth" data-aos="fade-left">
          <div className="bg-deg">
            <div className="container">
              <div className="row p-lg-3 py-lg-5 py-3">
                <div className="col-12 text-center mb-3">
                  <div className="logoC zoom"></div>
                </div>
                <div className="col-4 text-center border-end">
                  <h2 className="f-berlin text-light">
                    +9 Años de experiencia
                  </h2>
                </div>
                <div className="col-4 text-center">
                  <h2 className="f-berlin text-light">
                    +348 Lotes
                  </h2>
                </div>
                <div className="col-4 text-center border-start">
                  <h2 className="f-berlin text-light">
                    +50 Casas construidas
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="sixth" data-aos="fade-up">
          <div className="container my-5">
            <div className="row">
              <div className="col-12 text-center">
                <h1 className="f-berlin text-blue mb-3 mb-lg-5">
                  Localización y Puntos de Interés
                </h1>
                <a className="text-decoration-none" href="https://earth.google.com/web/@10.35648313,-75.36866339,104.98434235a,2806.7451665d,35y,11.46332004h,59.04477492t,-0r/data=MikKJwolCiExSnZaLXFsQnhLT29rS0ZoMDQzZzlzLXQ4d2Q3WnlSUGsgAToDCgEw?authuser=0" rel="noreferrer" target="_blank"> 
                  <p className="small text-secondary m-0 mb-lg-3">Echa un vistazo con un click en</p>
                  <img src={earth} alt="" width="300" className="zoom"/>
                  <img className="w-100 mb-3" src={map} alt=""/>
                </a>
              <h5 className="mb-3 text-center text-secondary">Los lotes karmairy hacen parte del gran proyecto de Macondo donde estará Pueblo Caribe.</h5>
              </div>
            </div>
          </div>
        </section>

        <section id="seventh" data-aos="flip-left">
          <div className="container my-5 pt-3 px-4">
            <div className="row p-2 py-3 p-lg-3 py-lg-5 bg-deg rounded-50">
              <div className="col-12 col-lg-2">
                <div className="support"></div>
              </div>
              <div className="col-12 col-lg-10 text-light">
                <h1 className="f-berlin text-center text-lg-start">Deja tus datos y obtén bono por 15 millones para la compra de tu Lote Campestre.</h1>
                <p>ESTAMOS EN PREVENTA, ÚLTIMAS UNIDADES EN OFERTA</p>
                <p className="fw-bold">Contacta con nuestros asesores para obtener mayor información</p>
                <form name="form" id="form">
                  <div className="row">
                    <div className="col-12 col-lg-4 p-2 px-3">
                      <input className="fw-light" type="text" placeholder="Ciudad" name="city"/>
                    </div>
                    <div className="col-12 col-lg-4 p-2 px-3">
                      <input className="fw-light" type="text" placeholder="Nombre" name="name"/>
                    </div>
                    <div className="col-12 col-lg-4 p-2 px-3">
                      <input className="fw-light" type="number" placeholder="Whatsapp" name="phone"/>
                      <button className="next-dark zoom border-0" onClick={() => wtp()} type="button"></button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
          
        <br/>
        <br/>
        <br/>
        <br/>
        <footer className="bg-blue p-lg-5 py-5" >
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-3 col-lg-2 text-center">
                <div className="logoD"></div>
              </div>
              <div className="col-3 col-lg-2 text-center">
                <div className="logoE"></div>
              </div>
              <div className="col-3 col-lg-2 text-center">
                <div className="logoF"></div>
              </div>
              <div className="col-12 col-lg-2 h3 text-center mt-4 my-lg-0">
                <a href="https://www.facebook.com/nombradiasas" rel="noreferrer" target="_blank">
                  <BsFacebook className="mx-2 text-light" />
                </a>
                <a href="https://www.instagram.com/nombradiaoficial/" rel="noreferrer" target="_blank">
                  <BsInstagram className=" mx-2 text-light" />
                </a>
                <a  href={`https://wa.me/c/${number}`} rel="noreferrer" target="_blank">
                  <BsWhatsapp className="mx-2 text-light" />
                </a>
                <br/>
                <br/>
                <h6 className="text-light"><BsFillTelephoneFill/> {number}</h6>
                <h6 className="text-light font">gerenciacomercialmacondo@gmail.com</h6>
              </div>
            </div>
            <div className="row font mt-lg-5 pt-3 px-lg-5">
              <div className="col-7 text-light">
                <small>© Nombradía, Todos los derechos reservados {date.getFullYear()}</small>
              </div>
              <div className="col-5 text-end text-light small">
                Web by
                <div className="uappex"></div>
              </div>
            </div>
          </div>
        </footer>

      </div>
    )
    
  }

export default Landing